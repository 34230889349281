.page_404 {
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1200px;
    margin: 70px auto 0;
    
    .container {
        width: 100%;

        .four_zero_four_bg {
            height: 400px;
            background-position: center;
            background-repeat: no-repeat;

        }
        .img-container {
            height: 600px;
            width: auto;
            img {
                height: 100%;
                width: 100%;
                object-fit: contain;
            }
            @media (max-width : 768px) {
                height: 300px;
            }
            @media (max-width : 576px) {
                height: 200px;
            }
        }
        h1 {
            font-size: 40px !important;
            line-height: 1.5;
            text-align: center;
            @media (max-width : 768px) {
                font-size: 30px !important;
            }
            @media (max-width : 576px) {
                font-size: 24px !important;
            }
        }
        p {
            font-size: 16px;
        }
        h3 {
            font-size: 80px;
        }
        .contant_box_404 {
                text-align: center;
        }
    }
    img {
        width: 100%;
    }
  }
  
  
  
  .link_404 {
    color: #fff !important;
    padding: 10px 20px;
    background: #39ac31;
    margin: 20px 0;
    display: inline-block;
  }

.page404 {
    height: 600px;
    width: 100%;
    .animateGif {
        // background: url("./../../../public/img/404/404_animation.gif");
        height: 400px;
        background-position: center;
        background-repeat: no-repeat;
    }
}