.megamenulist {
    position: fixed;
    top: 0;
    background-color: $bg-nav-primary;
    height: auto;
    width: 100%;
    z-index: 999;
    .menu-list {
        display: flex;
        flex-direction: column;
        .menu-item {
            padding: 10px 20px;
            color: rgba(255, 255, 255, 0.6);
            font-size: 14px;
            font-family: $primary-font;
            &:hover, :focus, :active {
                color: $white;
            }
        }
        .active {
            font-weight: 600;
            color: $white;
        }
    }
}