/*==================================
STYLE SWITHER - Demo purpose only
==================================*/

#style-switcher {
    .container {
        padding: 0;
        display: flex;
        flex-direction: column;
        h3 {
            font-size: 16px;
            border-bottom: 1px solid #92929230;
            padding: 10px 15px;
        }
        .color-picker {
            display: flex;
            flex-direction: row;
            position: unset;
            div {
                padding: 20px 0;
                display: flex;
                justify-content: center;
                border: 1px solid #92929210;
            }
        }
        .colors,
        .gradiant {
            input[type=color] {
                visibility: hidden;
                position: absolute;
            }
        }
    }
    .bottom {
        background: none repeat scroll 0 0 #FFFFFF;
        color: #252525;
        padding: 0;
    }
}

ul.pattern {
    list-style: none outside none;
    margin: 0 0 0px;
    // overflow: hidden;
    padding: 0;
    border-radius: 0px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;
    .colors-pallete {
        width: 33.33%;
        display: flex;
        justify-content: center;
        margin: 0px 0;
        padding: 15px 0px;
        border: 1px solid #92929210;
        border-radius: 10px;
        &.color1 {
            background-color: #fb3e3e20;
        }
        &.color2 {
            background-color: #1bbc9d20;
        }
        &.color3 {
            background-color: #f85f8920;
        }
        &.color4 {
            background-color: #00e6e620;
        }
        &.color5 {
            background-color: #704fff20;
        }
        &.color6 {
            background-color: #00103320;
        }
        &.color7 {
            background-color: #f0910520;
        }
        &.color8 {
            background-color: #f6cb4220;
        }
        &.color9 {
            background: linear-gradient(#00103320, #060A1520);
        }
        a {
            cursor: pointer;
            display: block;
            height: 35px;
            width: 35px;
            border-radius: 50%;
            margin: 5px;
            padding: 10px 15px; //----
            &.color1 {
                background-color: #fb3e3e;
            }
            &.color2 {
                background-color: #1bbc9d;
            }
            &.color3 {
                background-color: #f85f89;
            }
            &.color4 {
                background-color: #00e6e6;
            }
            &.color5 {
                background-color: #704fff;
            }
            &.color6 {
                background-color: #001033;
            }
            &.color7 {
                background-color: #f09105;
            }
            &.color8 {
                background-color: #f6cb42;
            }
            &.color9 {
                background: linear-gradient(#001033, #060A15);
            }
        }
    }
}

// @media only screen and (max-width: 479px) {
//     #style-switcher {
//         display: none;
//     }
// }

// --------------------------------
// Gradiant Color-Picker 
// --------------------------------

.colorPick {
    position: fixed;
    top: 20%;
    a.settings, span.settings {
        text-decoration: none;
        background: none repeat scroll 0 0 #2b2424;
        box-shadow: 2px 2px 0 0 rgba(0, 0, 0, 0.08);
        display: block;
        height: 41px;
        position: absolute;
        top: 0;
        width: 40px;
        padding: 3px;
        border-radius: 0 5px 5px 0;
        i {
            margin-left: 5px;
            color: $white;
            font-size: 24px;
            position: absolute;
        }
    }
}