// fonts family
$primary-font : "poppins";
$secondary-font : "poppins";

// colors 
$white : #fff;
$red : #fb3e3e;
$blue : #001033;
$cyan : #00e6e6;
$green : #1bbc9d;
$orange : #f09105;
$pink : #f85f89;
$purple : #704fff;
$yellow : #f6cb42;
$gray : #777;
$dark : #212121;
$dark80 : #21212180;
$white-80 : #FFFFFF80;

// background-colors
$bg-nav-primary : #2e3138;
$bg-footer : #2e3138;

// badge color
$badge : $white;  //for text
$bg-badge : $red;  // for background
