.privacy-policy-page.privacy-policy {
    max-width: 1200px;
    margin: auto;
    padding: 3rem 20px 5rem;
    text-align: justify;
  }
  .privacy-policy-page-heading {
    width: 100%;
    justify-content: center;
    margin: 20px 0;
  }
  
  .privacy-policy-page p {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 1.5;
    margin-bottom: 5px;
  }
  
  .privacy-policy .content-heading h4 {
    color: #18216d;
    font-size: 21px;
    line-height: 1.41;
    font-weight: 700;
  }
  
  .privacy-policy h5 {
    color: #18216d;
    font-size: 19px;
    line-height: 1.41;
    font-weight: 700;
  }
  
  .privacy-policy h6 {
    color: #18216d;
    font-size: 14px;
    line-height: 1.41;
    font-weight: 700;
  }