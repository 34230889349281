.careers-section {
    position: relative;
    padding: 5rem 0 4rem;
  }
  .careers-main {
    display: flex;
    width: 100%;
    justify-content: center;
  }
  .w-100 {
    width: 100%;
  }
  .flex {
    display: flex;
  }
  .justify-center {
    justify-content: center;
  }
  .align-center {
    align-items: center;
  }
  .page-title-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px 20px;
  }
  .careers-section .page-title {
    text-align: center;
    font-size: 28px;
    margin-bottom: 1rem;
    font-weight: 700;
  }
  .page-heading {
    font-size: 42px;
    /* line-height: 50px; */
    font-weight: 700;
    letter-spacing: 1px;
    margin-bottom: 15px;
    text-align: center;
  }
  .page-desc {
    text-align: center;
    font-size: 1rem;
    color: #000;
    font-weight: 600;
  }
  .careers-section .main-section-heading {
    text-align: center;
    font-size: 45px;
    line-height: 50px;
    font-weight: 700;
    letter-spacing: 0;
  }
  .careers-box {
    padding: 30px 0px;
    /* box-shadow: 10px 10px 10px grey; */
    width: 100%;
    margin: 8px 5px;
  }
  .career-box .ant-card-head-title {
    font-size: 1.8rem;
    color: #18216d;
    font-weight: 600;
  }
  .job-title {
    color: #18216d;
    font-size: 24px;
    margin-top: 0;
    margin-bottom: 0em;
  }
  .job-show-hide-icon .icon-plus,
  .job-show-hide-icon .icon-minus {
    font-size: 24px;
    color: #18216d;
  }
  .job-show-hide-icon {
    cursor: pointer;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .vacancy {
    margin: 0;
  }
  .job-desc-wrapper,
  .job-resp-wrapper {
    margin: 20px 0;
  }
  
  .career-box .job-content h4 {
    color: #000;
    font-size: 1.1rem;
    font-weight: normal;
  }
  
  .job-resp,
  .job-desc {
    font-size: 16px;
  }
  .job-apply-btn button {
    float: left;
  }
  .img-container {
    display: flex;
    flex-direction: row;
    .form-img {
      border: 2px solid #92929230;
      span {
        right: 5px;
      }
    }
  }
  
  @media only screen and (max-width: 1024px) {
    .careers-section {
      padding: 2.5rem 0 2.5rem;
    }
    .careers-section .page-title {
      font-size: 26px;
    }
    .page-title-wrapper {
      margin-bottom: 2rem;
    }
    .page-heading {
      font-size: 36px;
    }
  }
  @media screen and (max-width: 768px) {
    .career-box .ant-card-head-title {
      font-size: 1.5rem;
    }
    .careers-section .page-title {
      font-size: 24px;
    }
    .page-heading {
      font-size: 32px;
    }
    .page-title-wrapper {
      margin-bottom: 0;
    }
    .ant-card-body {
      padding: 12px;
    }
  }
  @media only screen and (max-width: 425px) {
    .careers-section .page-title {
      font-size: 18px;
    }
    .page-heading {
      font-size: 28px;
    }
    .careers-section .main-section-heading {
      font-size: 32px;
    }
    .career-box .ant-card-head-title {
      font-size: 1.2rem;
    }
    .careers-box {
      padding: 0;
      padding-top: 10px;
    }
    .page-heading {
      font-size: 30px;
    }
    .page-title-wrapper {
      margin-bottom: 1.5rem;
    }
    .card-titles {
      font-size: 18px !important;
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .ant-card-body {
      padding: 0px;
    }
  }
  @media only screen and (max-width: 320px) {
    .careers-section .page-title {
      font-size: 16px;
    }
    .page-heading {
      font-size: 24px;
    }
    .career-box .ant-card-head-title {
      font-size: 1rem;
    }
  }
  