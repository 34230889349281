.page-load {
    position: absolute;
    height: 100vh;
    width: 100%;
    background-color: $white;
    font-family: "poppins";
    z-index: 9999;
    .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100vh;
        .brand {
            height: 80px;
            width: 80px;
            img {
                height: 100%;
                width: 100%;
                object-fit: contain;
            }
        }
        .brand-text {
            margin-top: 30px;
            span {
                font-size: 50px;
                font-weight: 600;
                letter-spacing: 1.5px;
                // color: $blue;
                color: $white;
                position: absolute;
                transform: translate(-50%, -50%);
                &:nth-child(1) {
                    color: transparent;
                    -webkit-text-stroke: 1px $blue; 
                }
                &:nth-child(2) {
                    color: $blue;
                    animation: animate 4s ease-in-out infinite;
                }
                @keyframes animate {
                    0%,
                    100% {
                      clip-path: polygon(
                        0% 45%,
                        16% 44%,
                        33% 50%,
                        54% 60%,
                        70% 61%,
                        84% 59%,
                        100% 52%,
                        100% 100%,
                        0% 100%
                      );
                    }
                  
                    50% {
                      clip-path: polygon(
                        0% 60%,
                        15% 65%,
                        34% 66%,
                        51% 62%,
                        67% 50%,
                        84% 45%,
                        100% 46%,
                        100% 100%,
                        0% 100%
                      );
                    }
                  }
            }
        }
    }
}
