@font-face {
    font-family: "Motiva";
    src: url("./../../public/fonts/Motiva-Sans-Bold.ttf") format("truetype");
    font-style: normal;
    font-weight: 800;
}

@font-face {
    font-family: "Motiva";
    src: url("./../../public/fonts/Motiva-Sans-Light.ttf") format("truetype");
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: "poppins";
    src: url("./../../public/fonts/Poppins/Poppins-Light.ttf") format("truetype");
    font-style: normal;
    font-weight: 300;
}

@font-face {
    font-family: "poppins";
    src: url("./../../public/fonts/Poppins/Poppins-Regular.ttf") format("truetype");
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: "poppins";
    src: url("./../../public/fonts/Poppins/Poppins-Medium.ttf") format("truetype");
    font-style: normal;
    font-weight: 500;
}

@font-face {
    font-family: "poppins";
    src: url("./../../public/fonts/Poppins/Poppins-SemiBold.ttf") format("truetype");
    font-style: normal;
    font-weight: 600;
}

@font-face {
    font-family: "poppins";
    src: url("./../../public/fonts/Poppins/Poppins-Bold.ttf") format("truetype");
    font-style: normal;
    font-weight: 700;
}

@font-face {
    font-family: "poppins";
    src: url("./../../public/fonts/Poppins/Poppins-ExtraBold.ttf") format("truetype");
    font-style: normal;
    font-weight: 800;
}
